import React, { useState, useEffect } from 'react';
import './App.css';

const App = () => {
  const [tasks, setTasks] = useState([]);
  const [taskText, setTaskText] = useState('');
  const [taskDate, setTaskDate] = useState('');
  const [ws, setWs] = useState(null); // 用于存储 WebSocket 实例

  // WebSocket连接
  useEffect(() => {
    const socket = new WebSocket('ws://47.93.186.107:8080'); // 后端 WebSocket 服务器地址
    setWs(socket);

    socket.onopen = () => {
      console.log('WebSocket连接已建立');
      // 连接建立后请求服务器返回任务列表
      socket.send(JSON.stringify({ action: 'getTasks' }));  // 向服务器请求任务数据
    };

    socket.onmessage = (event) => {
      const updatedTasks = JSON.parse(event.data);
      setTasks(updatedTasks);
      console.log("Received tasks from WebSocket:", updatedTasks);
    };

    socket.onerror = (error) => {
      console.error('WebSocket发生错误:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket连接已关闭');
    };

    return () => {
      socket.close();
    };
  }, []);

  // 发送更新后的任务列表到服务器
  const saveTasks = (updatedTasks) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ action: 'updateTasks', tasks: updatedTasks })); // 向服务器发送任务更新
      console.log('Sending tasks to server:', updatedTasks);
    }
    setTasks(updatedTasks); // 更新本地任务列表
  };

  const addTask = () => {
    if (taskText.trim()) {
      const newTask = { text: taskText, completed: false, date: taskDate };
      const updatedTasks = [...tasks, newTask];
      saveTasks(updatedTasks);
      setTaskText('');
      setTaskDate('');
    }
  };

  const toggleTaskCompletion = (index) => {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, completed: !task.completed } : task
    );
    saveTasks(updatedTasks);
  };

  const deleteTask = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    saveTasks(updatedTasks);
  };

  const handleDateChange = (e, index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].date = e.target.value;
    saveTasks(updatedTasks);
  };

  // 按照截止日期排序任务列表（使用副本避免直接修改原数组）
  const sortedTasks = [...tasks].sort((a, b) => {
    if (a.date && b.date) {
      return new Date(a.date) - new Date(b.date);
    }
    return 0;
  });

  return (
    <div>
      <h1>任务清单</h1>
      <input
        type="text"
        value={taskText}
        onChange={(e) => setTaskText(e.target.value)}
        placeholder="输入任务"
      />
      <button onClick={addTask}>添加任务</button>
      <ul>
        {sortedTasks.map((task, index) => (
          <li key={index}>
            <input
              type="checkbox"
              checked={task.completed}
              onChange={() => toggleTaskCompletion(index)}
            />
            <span style={{ textDecoration: task.completed ? 'line-through' : 'none' }}>
              {task.text}
            </span>
            <input
              type="date"
              value={task.date || ''}
              onChange={(e) => handleDateChange(e, index)}
            />
            <button className="delete" onClick={() => deleteTask(index)}>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;
